import React from "react";
import "./index.css";
import LoginPage from "./pages/LoginPage";

function App() {
  return (
    <div>
      <LoginPage />
    </div>
  );
}

export default App;
